import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useRouter } from 'next/router';
import { useVigoLogout } from 'src/@core/hooks/useVigoLogout';

const whitelist = ['/dashboards/[name]', '/dashboards/development', '/signedout', '/tracking'];

export const IdleTimer = () => {
  const router = useRouter();
  const minutesBeforeSignout = 20;

  const { handleLogout } = useVigoLogout();

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const goBackToLogin = useCallback(() => {
    handleLogout();
  }, [handleLogout]);

  const restartAutoReset = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      goBackToLogin();
    }, minutesBeforeSignout * 1000 * 60);
  }, [goBackToLogin, minutesBeforeSignout]);

  const preventReset = useMemo(() => {
    if (window.location.origin === 'http://localhost:3000') {
      return true;
    }

    return whitelist.includes(router.pathname);
  }, [router.pathname]);

  useEffect(() => {
    if (preventReset) {
      console.log('Preventing reset for path:', router.pathname);

      return;
    }

    restartAutoReset();

    window.addEventListener('mousemove', restartAutoReset);
    window.addEventListener('keydown', restartAutoReset);

    return () => {
      if (timeoutRef) {
        clearTimeout(timeoutRef.current as NodeJS.Timeout);
      }
      window.removeEventListener('mousemove', restartAutoReset);
      window.removeEventListener('keydown', restartAutoReset);
    };
  }, [preventReset, restartAutoReset, router.pathname]);

  return null;
};
